<template>
  <div>
    <div>
      <div class="mainTitle">教职工信息</div>
      <div class="content">
        系统维护全体教职工信息，个人基本信息变动实时更新。
      </div>
      <img src="@/assets/productcore/renli1.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">学员信息</div>
      <div class="content">
        基础信息/个人信息/成绩管理<br />动态信息/学籍异动/学业管理/学位授予。
      </div>
      <img src="@/assets/productcore/renli2.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">干部评价</div>
      <div class="content">
        系统提供对干部评价功能，根据学院对学员干部的评价要求，制定评价活动，方便教师、领
        导能够全面了解学员干部能力。
      </div>
      <img src="@/assets/productcore/renli3.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">请假管理</div>
      <div class="content">
        为全校师生提供请假审批功能，所有人员根据实际情况，提出请假申请。
      </div>
      <img src="@/assets/productcore/renli4.png" alt="" />
    </div>
     <div>
      <div class="mainTitle">请假分析</div>
      <div class="content">
       请假人数统计，请假部门统计，请假地区统计，图形样例分，下钻细则分析。
      </div>
      <img src="@/assets/productcore/renli5.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "manpower-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>